
import { computed, defineComponent, onMounted, ref, nextTick, watch} from "vue";
import Swal from "sweetalert2";
import { useForm } from "vee-validate";
import IstQuestionAnsweringComponent from "@/views/psy/ist/component/IstQuestionAnsweringComponent.vue";
import ApiService from "@/core/services/ApiService";
import Services from "@/core/services/ArrService";

interface IST_Group{
  IST_Duration : number
  IST_Group_Id: string
  IST_Group_Name: string
  IST_Group_Type : string
  IST_Questions_Number: string
  completed: boolean
  isExample: boolean
}

export default defineComponent({
  name: "cfit-usr",
  components: {
    IstQuestionAnsweringComponent,
  },

  setup() {
    const verticalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const groupCache = ref([] as Array<IST_Group>);
    var isShowButton = ref(false)
    var max_time = ref(0)
    var current_time = ref(0)
    var remainingTime = ref(0)
    var timerInterval = ref(0)
    var timerRunning = ref(false)

    const { handleSubmit } = useForm();

    const handleStep = handleSubmit(() => {
      // Mark the current step as completed
      groupCache.value[currentStepIndex.value].completed = true;
      isShowButton.value = false
      // Move to the next step
      if (currentStepIndex.value < groupCache.value.length - 1) {
        currentStepIndex.value++;
      }
    });

    const previousStep = () => {
      if (currentStepIndex.value > 0) {
        currentStepIndex.value--;
      }
    };

    function changeShowButton(val) {
      isShowButton.value = true
      groupCache.value[currentStepIndex.value].isExample = false;
    }

    function initDuration(){
      timerRunning.value = false;
      clearInterval(timerInterval.value);  
      max_time.value = groupCache.value[currentStepIndex.value].IST_Duration * 60
      current_time.value = max_time.value
      remainingTime.value = current_time.value
      if(current_time.value == -1)
        remainingTime.value = max_time.value
    }

    const formSubmit = () => {
      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        confirmButtonText: "Ok, got it!",
      }).then(() => {
        window.location.reload();
      });
    };

    onMounted(() => {
      // Fetch your groups or any initial data here
      getIstGroup();
    });

    async function getIstGroup(){
      const mydatat = {
        Trigger: "R",
        Route: "IST_Groups",
        IST_Group_Id: ""
      };

      groupCache.value = [];
      await Services.PostDataXWWW(ApiService, "IST", mydatat, response => {
        groupCache.value = response.data.map(item => ({ ...item, completed: false, isExample:true, }));
        groupCache.value.push({
          completed: false, 
          isExample: true,
          IST_Duration: 0,
          IST_Group_Id: "dummy",
          IST_Group_Name: "999",
          IST_Group_Type: "",
          IST_Questions_Number: ""
        })
        initDuration()
      }, err => {
        console.error(err);
      });
    }

    function goingUp(){
      nextTick(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top
      });
    }

    function formattedTime() {
      const minutes = Math.floor(current_time.value / 60);
      const seconds = current_time.value % 60;
      const result = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
      return result; // Format as mm:ss
    }

    watch(remainingTime, (newVal, oldVal) => {
      current_time.value = newVal;
      // Reset progress bar jika waktu telah habis
      if (newVal == 0 && isShowButton.value) {
        clearInterval(timerInterval.value);
        handleStep()
        // is_ended.value = true
        // await updateParticipantHistory(newVal)
      }
    });

    watch(isShowButton, (newVal, oldVal) => {
      //false mean example, true mean main question
      goingUp()
      if(newVal){
        if(!timerRunning.value && remainingTime.value > 0){
          startTimer()
        }else{
          handleStep()
        }
      }else{
        initDuration()
      }
    });

    function startTimer() {
      if (!timerRunning.value && remainingTime.value > 0) { // Tambahkan pengecekan timerRunning
        timerRunning.value = true; // Set timerRunning menjadi true
        // Set interval untuk mengurangi remainingTime setiap detik
        timerInterval.value = setInterval(() => {
          remainingTime.value--;
        }, 1000);
      }
    }

    return {
      verticalWizardRef,
      currentStepIndex,
      groupCache,
      handleStep,
      previousStep,
      formSubmit,
      isShowButton,
      changeShowButton,
      current_time,
      max_time,
      formattedTime,
    };
  },
});
